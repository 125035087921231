import {Layout} from "../components/Layout";
import React, {useEffect} from 'react';
import {graphql} from "gatsby";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {BackgroundLine} from "../components/BackgroundLine";
import {CareersJobs} from "../components/CareersJobs";
import {Typography} from "../components/Typography";
import {initConfetti} from "../functions/initConfetti";
import {BlockTitle} from "../components/BlockTitle";
import {MarkdownContent} from "../components/MarkdownContent";
import {GatsbyImage} from "gatsby-plugin-image";
import {SVGSupportImg} from "../components/SVGSupportImg";

const styles = require('./ggsPeopleRocks.module.scss');

export const query = graphql`
    query GGSPeopleRocks($locale: String!) {
        people: allStrapiEmployee(sort:{fields: lastName, order: DESC}) {
            nodes {
                name
                lastName
                description {
                    data {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 300
                                height: 300
                                placeholder: NONE,
                                transformOptions: {fit: COVER, cropFocus: CENTER}
                                layout: FIXED
                            )
                        }
                    }
                }
            }
        },
        jobs: allStrapiJobOffer(filter: {locale: {eq: $locale}}) {
            edges {
                node {
                    name
                    url
                    description {
                        data {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function GSSPeopleRocksPage({data, pageContext}) {
    useEffect(() => {
        initConfetti()
    }, []);

    const onImageClick = () => {
        window.confetti && window.confetti({
            spread: 120,
            particleCount: 100,
        });
    };

    const people = data.people.nodes;

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   noIndex={true}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={'GGS People Rocks'}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                       <BackgroundLine position={"flexion"}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <BlockTitle className={styles.mainTitle}>GGS People Rocks!</BlockTitle>
            <div className={styles.peopleWrapper}>
                {
                    people.map((data, index) => {
                        const image = data.image?.[0];
                        return <People
                            title={data.lastName + ' ' + data.name}
                            description={data.description.data}
                            image={image?.localFile}
                            onImageClick={onImageClick}
                            key={index}
                        />
                    })
                }
            </div>
        </PageMainColumnWrapper>
        <CareersJobs jobs={data.jobs.edges.map(x => x.node)}
                     title="Still scrolling?"
                     content={{childMarkdownRemark: {html: "We are still hiring, check this out:"}}}
        />
    </Layout>
}


function People({title, description, image, onImageClick}) {
    return <article className={styles.people}>
        <h1 className={styles.peopleTitle}>{title}</h1>
        <div className={styles.peopleImage} onMouseOver={(e) => {
            onImageClick(e.target);
        }}>
            {image && <SVGSupportImg {...image}
                                     alt={title}

            />}
        </div>
        <MarkdownContent component={Typography} content={description} className={styles.peopleDescription}/>
    </article>
}
