import loadJS from "loadjs";

const ID = 'confetti';

export function initConfetti() {
    if (!loadJS.isDefined(ID)) {
        return loadJS('https://cdn.jsdelivr.net/npm/canvas-confetti@1.4.0/dist/confetti.browser.min.js', ID, {
            returnPromise: true
        });
    }
    return Promise.resolve(undefined);
}
